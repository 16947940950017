import React, { useState } from 'react';
import './Sidebar.css';
import { FaHome, FaUserTie, FaChartBar, FaChevronLeft, FaBars } from 'react-icons/fa';
import paddedIcon from '../assets/paddedicon.png';

function Sidebar({ setActiveView, activeView }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleViewChange = (view) => {
    setActiveView(view);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const navItems = [
    { view: 'homeowners', icon: FaHome, label: 'Homeowners' },
    { view: 'contractors', icon: FaUserTie, label: 'Contractors' },
    { view: 'reports', icon: FaChartBar, label: 'Reports' },
  ];

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar__logo">
        {isCollapsed ? (
          <img src={paddedIcon} alt="HomeRunns Icon" className="sidebar__icon" />
        ) : (
          <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" />
        )}
      </div>
      <nav className="sidebar__nav">
        {navItems.map(({ view, icon: Icon, label }) => (
          <button
            key={view}
            className={`sidebar__nav-button ${activeView === view ? 'active' : ''}`}
            onClick={() => handleViewChange(view)}
          >
            <Icon className="sidebar__nav-icon" />
            {!isCollapsed && <span>{label}</span>}
          </button>
        ))}
      </nav>
      <div className="sidebar__toggle" onClick={toggleSidebar}>
        {isCollapsed ? <FaBars /> : (
          <>
            <FaChevronLeft className="sidebar__toggle-icon" />
            <FaChevronLeft className="sidebar__toggle-icon" />
          </>
        )}
      </div>
    </div>
  );
}

export default Sidebar;